Number.prototype.formatBytes = ()->
  bytes = this
  if bytes == 0
    return '0 Byte'

  sizes = ['Bytes', 'KB', 'MB', 'GB']
  unit = Math.floor(Math.log(bytes)/Math.log(1024))
  "#{parseFloat((bytes / Math.pow(1024, unit)).toFixed(2))} #{sizes[unit]}"

Number.prototype.round = (places = 0)->
  +(Math.round(this + "e+#{places}") + "e-#{places}")

Number.prototype.toTimeString = ()->
  seconds = this
  return '00:00' if seconds == 0

  minutes = Math.floor(seconds / 60)
  minutes = '0' + minutes if minutes.toString().length == 1
  seconds = Math.round(seconds % 60)
  seconds = '0' + seconds if seconds.toString().length == 1

  "#{minutes}:#{seconds}"