importAll = (context)-> context.keys().forEach(context)
importAll(require.context('./controllers/admin', true, /\.coffee$/))

window.ajaxDeleteButton = ()->
  $.ajax
    url: $(this).data('url')
    method: 'POST',
    data: { _method: 'DELETE' },
    dataType: 'script'

window.regularDeleteButton = ()->
  button = $("<a data-method='delete' data-remote='true' href='#{$(this).data('url')}'>")
  button.on 'click', ( (event)-> Rails.handleMethod.call(this, event) )
  button.click()

window.captureModals = ()->
  container = $('.inline-form-container')
  $('[data-toggle=modal]:not(.captured)').addClass('captured').on 'click', (event)->
    event.preventDefault()
    event.stopPropagation()

    url = $(this).attr('href')
    $.ajax
      url: url,
      method: 'GET',
      success: (response)->
        container.html(response).fadeIn()
        $('[data-dismiss=modal]').show().on 'click', (event)->
          event.preventDefault()
          container.fadeOut()

        triggerAfterAjax(url)

window.datatableShowChildRow = (caller, column)->
  table = window.datatables[caller.parents('table').prop('id')]
  row = table.row(caller.parents('tr'))

  if row.child.isShown()
    row.child.hide()
    caller.html(caller.html().replace('Hide', 'Show'))
  else
    row.child("<div class='child-row'>#{row.data()[column]}</div>").show()
    caller.html(caller.html().replace('Show', 'Hide'))

window.handleClipboard = (element)->
  element.on 'click', (event)->
    event.preventDefault()

  clipboard = new ClipboardJS("##{element.prop('id')}")
  clipboard.on 'success', (e)->
    e.clearSelection()
    quickTooltip(element, 'Copied!')
  clipboard.on 'error', (e)->
    if /Mac/i.test(navigator.userAgent)
      msg = 'Press ⌘-C to copy'
    else
      msg = 'Press Ctrl+C to copy'
    quickTooltip(element, msg)

window.handleColorPickerTransparent = ()->
  $('.minicolors-transparent').each ()->
    $(this).on 'click', ()->
      $(this).parent().siblings('.minicolors').find('input').minicolors('value', 'transparent')

window.handleDatatables = ()->
  window.datatables = {}
  $('.datatable').each ()->
    id = $(this).prop('id')
    options = $(this).data('datatable')
    config = {
      processing: true,
      serverSide: true,
      ajax: options.source,
      columns: options.columns,
      order: options.order,
      createdRow: (row, data, index)->
        row = $(row)
        row.addClass(data.DT_RowClass)
        if data.DT_RowData?
          for key, value of JSON.parse(data.DT_RowData)
            row.attr(key, value)
      drawCallback: ()->
        triggerAfterDatatableLoad(id)
    }
    for option, value of options.custom
      config[option] = value
    window.datatables[id]  = $(this).DataTable(config)

  $('.datatable').dataTable().fnFilterOnReturn()

window.handleModals = ()->
  $('.modal-form:not(.processed)')
    .addClass('processed')
    .on 'hidden.bs.modal', ()->
      $(this).find('.modal-content').html($('.default-modal-content').html())
    .on 'show.bs.modal', (event)->
      if event.relatedTarget?
        url = $(event.relatedTarget).attr('href')
        $.get url, (response)=>
          $('.modal-form .modal-content').html(response)
          $(this).scrollTop(0)
          $(this).find('.portlet-body').scrollTop(0)
          triggerAfterAjax(url)

  $('.modal-form .modal-content').html($('.default-modal-content').html())

window.handlePieCharts = ()->
  $('.easy-pie-chart .number.visits').easyPieChart
    animate: 1e3, size: 75, lineWidth: 3,
    barColor: Metronic.App.getBrandColor("green")

window.quickTooltip = (element, msg, placement = 'right')->
  element
    .data('toggle', 'tooltip')
    .data('placement', placement)
    .prop('title', msg)
    .tooltip('show')
    .on 'hidden.bs.tooltip', ()->
      $(this).tooltip('dispose')

window.reloadDatatable = (id)->
  datatable = window.datatables[id]
  return unless datatable?
  datatable.ajax.reload ()->
    triggerAfterDatatableLoad(id)

window.triggerAfterAjax = (url = null)->
  Metronic.App.initAjax()
  Metronic.ComponentsBootstrapSelect.init()
  Metronic.ComponentsCkeditor.init()
  Metronic.ComponentsColorPickers.init()
  Metronic.ComponentsDateTimePickers.init()
  Metronic.ComponentsLightbox.init()
  handleColorPickerTransparent()

  if url?
    parts = url.split('.')[0].match(/[A-Za-z_]+/g)
    action = parts.pop()
    controller = parts.join('/')
    Manager.controller(controller).trigger(action) if controller? && action?

window.triggerAfterDatatableLoad = (tableId)->
  Metronic.App.initBootstrapConfirmation();
  Manager.datatable(tableId).trigger('load')
  $('[data-custom-function]').on 'click', ()->
    name = $(this).data('customFunction')
    window[name]?($(this))

window.updateCKEditor = (name, content)->
  instance = CKEDITOR.instances[name]
  if instance?
    instance.setData(content)
    instance.updateElement()

window.updateChart = (chart, title, options, beforeUpdate = null)->
  $("*[data-chart=#{chart}] .caption-helper").html("(#{title})")
  $("##{chart}").html('Loading...')

  beforeUpdate?()

  instance = Chartkick.charts[chart]
  $.extend(true, instance.options.library, options)
  for name, value of options
    instance.dataSource = instance.dataSource.replace(new RegExp("#{name}=[^&]*"), "#{name}=#{value}")
  instance.refreshData()

window.setupPlayer = (data, controller, action)->
  player = new USeek.Player.Core()
  player.initialize(video: data, options: { admin: true })
  Manager.player(controller).trigger(action, player)

$ ()->
  handleDatatables()
  handleModals()

  $('.collapse-button').on 'click', ()->
    target = $($(this).data('target'))
    if target.is(':visible')
      $(this).html('Read more')
    else
      $(this).html('Close')
    $(this).blur()

  $('.page-messages .alert.alert-dismissable').each ()->
    setTimeout(( ()=> $(this).fadeOut() ), 5000)

  $('[data-toggle="tooltip"]').tooltip({ container: 'body' })