Manager.controller 'admin/games',
  form: ()->
    window.deleteGameRank = (button)->
      rankRow = button.parents('.game-rank-row')
      if rankRow.hasClass('dynamic-row')
        rankRow.remove()
      else
        rankRow.find('input[type=checkbox]').prop('checked', true)
        rankRow.hide().addClass('deleted')

    changeGameType = (type, initialize = false)->
      $('.type-settings').hide()
      $(".type-#{type}").show()

      unless initialize
        isTmobile = type == 'tmobile'
        $('#game_claim_facebook').prop('checked', !isTmobile)
        $('#game_claim_email').prop('checked', !isTmobile)
        $('#game_claim_phone_number').prop('checked', isTmobile)
        $('#game_share_email').prop('checked', !isTmobile)

      $('#registration-settings-panel, #share-settings-panel').toggle(type != 'ranking')

    $('#game_type').on 'change', (event)->
      changeGameType($(this).val())

    changeGameType($('#game_type').val(), true)

    $('#game-form').on 'ajax:before', (event)->
      $(".type-settings:not(.type-#{$('#game_type').val()})").remove()

    $('#add-new-rank').on 'click', (event)->
      container = $('#game-ranks-list')
      rankHtml = container.find('.game-rank-row:first').clone()
      rankIndex = container.find('.game-rank-row').length
      rankHtml.html(rankHtml.html().replace(/\[0\]/g, "[#{rankIndex}]"))
      rankHtml.html(rankHtml.html().replace(/_0_/g, "_#{rankIndex}_"))
      container.append(rankHtml)
      rankHtml.addClass('dynamic-row')
      rankHtml.find('input[type=text]').val('')
      rankHtml.find('input[type=number]').val(0)
      rankHtml.find('.game-rank-image').remove()