Metronic.ComponentsBootstrapSelect = function () {
    var handleBootstrapSelect = function() {
        if($().selectpicker) {
            $('.bs-select').selectpicker({
                iconBase: 'fas',
                tickIcon: 'fa-check',
                liveSearch: true,
                style: ''
            });
        }
    };

    return {
        //main function to initiate the module
        init: function () {      
            handleBootstrapSelect();
        }
    };

}();

if (Metronic.App.isAngularJsApp() === false) {
    jQuery(document).ready(function() {    
        Metronic.ComponentsBootstrapSelect.init();
    });
}