Manager.controller 'admin/prizes',
  setup: (element)->
    addPeriod = element.find('.add-period')
    instantOnly = element.find('.instant-prize-only')
    value = element.find('#prize_type').val()

    prizePeriodHide = (period, remove = false)->
      period.find('input[type=checkbox]').prop('checked', true)
      period.hide()

      return unless remove
      if period.hasClass('dynamic-row') then $(this).remove() else $(this).addClass('deleted')

    prizePeriodShow = (period)->
      period.find('input[type=checkbox]').prop('checked', false)
      period.show()

    prizePeriodRemove = (event)->
      prizePeriodHide($(event.target).closest('.period-row'), true)

    if value == 'instant' then instantOnly.removeClass('hidden') else instantOnly.addClass('hidden')
    if value == 'grand' then addPeriod.addClass('hidden') else addPeriod.removeClass('hidden')
    for period in element.find('.period-row:not(.deleted):not(:first)')
      if value == 'grand' then prizePeriodHide($(period)) else prizePeriodShow($(period))

    element.find('.delete-button:first').hide()
    element.find('.delete-button').on('click', prizePeriodRemove)
    Metronic.ComponentsDateTimePickers.init()

    unless addPeriod.hasClass('ready')
      addPeriod.addClass('ready').on 'click', (event)->
        container = $(event.target).siblings('.periods-list')
        periodHtml = container.find('.period-row:first').clone()
        periodIndex = container.find('.period-row').length
        periodHtml.html(periodHtml.html().replace(/\[0\]/g, "[#{periodIndex}]"))
        periodHtml.html(periodHtml.html().replace(/_0_/g, "_#{periodIndex}_"))
        periodHtml.html(periodHtml.html().replace(/period_start-picker/g, "period_start-picker-#{periodIndex}"))
        periodHtml.html(periodHtml.html().replace(/period_end-picker/g, "period_end-picker-#{periodIndex}"))
        container.append(periodHtml)
        periodHtml.addClass('dynamic-row')
        periodHtml.find('input[type=text]').val('')
        periodHtml.find('.delete-button').show().on('click', prizePeriodRemove)
        Metronic.ComponentsDateTimePickers.init()