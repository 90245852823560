require('./application');
global.bootstrap = require('bootstrap');
require('bootstrap-confirmation2');
require('bootstrap-select/js/bootstrap-select');
require('chartkick');
require('ckeditor/bootstrap-modal-fix');
require('@claviska/jquery-minicolors');
global.ClipboardJS = require('clipboard');
require('datatables.net');
require('datatables.net-bs4');
require('datatables.net-plugins/api/fnFilterOnReturn');
require('easy-pie-chart/dist/jquery.easypiechart');
require('ekko-lightbox');
global.moment = require('moment');
require('tempusdominus-bootstrap-4');
require('javascripts/classes/manager');
require('javascripts/utils/string');
require('javascripts/metronic/index');
require('javascripts/admin');
require('stylesheets/admin');