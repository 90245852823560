import Sortable from 'sortablejs'

Manager.controller 'admin/homepage/logos',
  index: ()->
    $('#homepage-logo-upload').on 'change', ()->
      $('#homepage-logo-form').submit()

    $('#save-changes-button').on 'click', ()->
      ids = $('.logo-box:visible').map( ()-> $(@).data('recordId') ).get()
      $.post '/admin/homepage/logos/reorder.js', { ids: ids }

    new Sortable($('.logos-list')[0], draggable: '.logo-box', animation: 150)