Manager.controller 'admin/games',
  ad_links: ()->
    linkTypes = ['mraid', 'simid', 'vast2', 'vast3', 'vast4', 'vpaid']

    for type in linkTypes
      handleClipboard($("#game-#{type}-button"))

    updateAdLinks = ()->
      baseUrl = $('#ad-links-base-url').data('url')
      affId = $('#vpaid_config_aff_id').val()
      usePoster = $('#vpaid_config_poster').prop('checked')

      options = []
      options.push("aff_id=#{affId}") if affId.length > 0
      options.push("use_poster=1") if usePoster

      for type in linkTypes
        link = baseUrl + type
        link += "?#{options.join('&')}" if options.length > 0
        $("#game-#{type}-url").val(link)

    $('#vpaid_config_aff_id').on 'change keyup', ()->
      $('#vpaid-config-events').toggleClass('hidden', $(this).val().length == 0)
      updateAdLinks()

    $('#vpaid_config_poster').on 'change', ()->
      updateAdLinks()

    if $('#vpaid_config_aff_id').val().length > 0
      $('#vpaid-config-events').removeClass('hidden')