require('prismjs')

Manager.controller 'admin/clients',
  tracking: ()->
    code = $('#tracking-code').val()
    jsPlaceholder = code.match(/useekClient.track(.*?);/)[0]
    imgPlaceholder = code.match(/<img(.*?)>/)[0]
    trackingUrl = imgPlaceholder.match(/\/\/(.*?)\?/)[0]
    clientId = imgPlaceholder.match(/client_id=(.*?)&/)[1]

    setupOptionsButton = (element)->
      element.on 'click', ()->
        optionsRow = $(this).siblings('.event-options-row:first').clone()
        optionsRow.find('input').val('')
        $(this).closest('.event-options').append(optionsRow)

    updateTrackingCode = ()->
      jsEvents = []
      imgEvents = []
      index = 0
      for event in $('.event-row')
        eventName = $(event).find('.event-name input').val()
        eventOptions = {}
        for options in $(event).find('.event-options-row')
          eventOptions[$(options).find('input:first').val()] = $(options).find('input:last').val()

        jsCode = "useekClient.track('#{eventName}', #{JSON.stringify(eventOptions)});"
        imgOptions = ("options[#{name}]=#{value}" for name, value of eventOptions)
        imgCode = "<img width='1' height='1' style='display: none' src='#{trackingUrl}client_id=#{clientId}&name=#{eventName}&#{imgOptions.join('&')}' />"

        if(index > 0)
          jsCode = "    #{jsCode}"
          imgCode = "  #{imgCode}"

        jsEvents.push(jsCode)
        imgEvents.push(imgCode)
        index++

      newCode = code
      newCode = newCode.replace(jsPlaceholder, jsEvents.join("\n"))
      newCode = newCode.replace(imgPlaceholder, imgEvents.join("\n"))
      $('#tracking-code').val(newCode)

      $('#tracking-script').html(Prism.highlight(newCode, Prism.languages.javascript, 'javascript'))

    $('#add-tracking-event').on 'click', ()->
      eventRow = $('.event-row:first').clone()
      eventRow.find('input').val('')
      eventRow.find('.event-options-row:not(:first)').remove()
      setupOptionsButton(eventRow.find('#add-event-options'))
      $('.event-row:last').after(eventRow)

    $('#update-tracking-code').on 'click', ()->
      updateTrackingCode()

    setupOptionsButton($('#add-event-options'))
    updateTrackingCode()
    handleClipboard($('#tracking-clipboard'))