Manager.controller 'admin/data_copies',
  index: ()->
    $('#environment').on 'change', ()->
      $.ajax
        url: '?environment='+$(this).val(),
        dataType: 'script',
        type: 'GET',
        beforeSend: (()-> $('#loader').show() )
        complete: (()-> $('#loader').hide() )

    window.showLogHistory = (caller)->
      datatableShowChildRow(caller, 'history')

  games: ()->
    @trigger('index')

  prizes: ()->
    @trigger('index')

  charities: ()->
    @trigger('index')