Array::avg = ->
  parseFloat(@sum())/@length

Array::max = ->
  Math.max.apply(null, @)

Array::min = ->
  Math.min.apply(null, @)

Array::sum = ->
  @reduce ( (a,b)-> a+b )

Array::first = ->
  @[0]

Array::last = ->
  @[@length-1]

Array::any = ->
  @length > 0

Array::empty = ->
  @length == 0

Array::uniq = ->
  output = {}
  output[@[key]] = @[key] for key in [0...@length]
  value for key, value of output

Array::flatten = ->
  @reduce(((a,b) -> if Array.isArray(b) then a.concat(b.flatten()) else a.concat(b)), [])

Array::compact = ->
  item for item in @ when item?