global.h337 = require('heatmap.js')

Manager.controller 'admin/games',
  heatmap: ()->
    renderHotspotsOnChart = ()->
      chartObserver = setInterval ()->
        player = USeek.player()
        svg = $('#clicks-per-second-chart svg')

        if(svg.length > 0 && player.ready())
          clearInterval(chartObserver)
          svg.addClass("hotspots-rendered")

          columns = svg.find('g[clip-path] g:nth-child(2) rect')
          height = parseInt(svg.find('g rect:first-child').height())

          for hotspot in player.getVideo().hotspots
            startColumn = Math.floor(hotspot.startTime)
            startX1 = parseInt($(columns[startColumn-1]).attr('x'))
            startX2 = parseInt($(columns[startColumn]).attr('x'))
            x1 = (startX1+(startX2-startX1)*(hotspot.startTime-startColumn)).round(2)

            endColumn = Math.floor(hotspot.endTime)
            endX1 = parseInt($(columns[endColumn-1]).attr('x'))
            endX2 = parseInt($(columns[endColumn]).attr('x'))
            x2 = (endX1+(endX2-endX1)*(hotspot.endTime-endColumn)).round(2)

            el = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
            el.setAttribute('x', x1)
            el.setAttribute('y', 0)
            el.setAttribute('width', x2-x1)
            el.setAttribute('height', height + 60)
            el.setAttribute('fill', 'darkgreen')
            el.setAttribute('style', 'opacity: 0.1')
            svg[0].appendChild(el)

      , 100

    $('#filter_clicks').on 'change', ()->
      updateChart('clicks-per-second', $(this).find("option[value=#{$(this).val()}]").text(), filter: $(this).val(), renderHotspotsOnChart)

    $(window).on 'resize', ()->
      renderHotspotsOnChart() unless $('#clicks-per-second-chart svg').hasClass("hotspots-rendered")

    renderHotspotsOnChart()

Manager.player 'admin/games',
  heatmap: (player)->
    container = $('#video-container')
    heatmap = h337.create(container: container[0])
    lastPosition = null

    player.bind 'update-timer', (attr)->
      startTime = Math.floor(attr.position/5)*5
      if !lastPosition? || startTime > lastPosition || player.tech('paused')
        lastPosition = startTime
        $.ajax
          url: "/admin/games/#{window.gameId}/heatmap",
          data: {
            width: container.width(),
            height: container.height(),
            start_time: startTime
            end_time: startTime+5,
            filter: $('#filter_clicks').val()
          },
          dataType: 'json',
          success: (data, status, response)->
            heatmap.setData(data)