Manager.controller 'admin/surveys',
  form: ()->
    renderQuestionSelect = ()->
      activeQuestions = ($(row).data('question-id') for row in $('.question-row'))
      available = (question for question in window.questions when !(question.id in activeQuestions))
      options = ("<option value=#{question.id}>#{question.question}</option>" for question in available)
      $('.add-question select')
        .html("<option value=''>--- Select Question ---</option>#{options.join('')}")
        .selectpicker('refresh')

    loadQuestionForm = (questionId)->
      $.ajax
        url: '/admin/surveys/question_row.js'
        method: 'POST'
        data: { question_id: questionId }
        success: (response)->
          questionRow = $(response).find('.question-row')
          $('.questions-list').append(questionRow)
          renderQuestionSelect()
          setupQuestionRow(questionRow)
          Metronic.ComponentsBootstrapSelect.init()

    setupQuestionRow = (row)->
      row.find('.delete-question').on 'click', ()->
        row.fadeOut ()->
          row.remove()
          renderQuestionSelect()

      row.find('.edit-question').on 'click', ()->
        row.find('input:disabled, select:disabled').prop('disabled', false).prop('title', '')
        row.find('select.bs-select').selectpicker('refresh')

      row.find('input.question-field').on 'keyup', ()->
        row.find('.question-label').html($(this).val())

      showTypeSettings = (type)->
        row.find('.type-settings').hide()
        row.find(".type-#{type}").show()

      row.find('select.question-type').on 'change', ()->
        showTypeSettings($(this).val())

      showTypeSettings(row.find('select.question-type').val())

    $('.add-question-button').on 'click', (event)->
      select = $(event.target).parent().find('select')
      loadQuestionForm(select.val()) if select.val().length > 0
    $('.new-question-button').on 'click', (event)->
      loadQuestionForm()
    $('.question-row').each (index, row)->
      setupQuestionRow($(row))

    renderQuestionSelect()