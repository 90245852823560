window.USeek ||= {}

class USeek.Wizard
  constructor: ()->
    @_current = null
    @_steps = []

    wizard = $('#game-wizard')

    for step in wizard.find('ol.steps li')
      step = $(step)
      @_steps.push
        id: step.prop('id'),
        html: {
          buttons: step.find('.buttons'),
          header: step.find('.header'),
          inline: step.find('.inline-content'),
          resources: step.find('.resources')
        }
        node: step,
        options: step.data(),
        resources: {}

    resources = $('#wizard-resources')
    if resources.length > 0
      for resource in resources.children()
        resource = $(resource)
        step = @_findStepById("#{resource.prop('class')}-step")
        @_addResource(resource.data(), step)
      for step in @_steps
        @_current = step
        @_completeStep()
        @_node().show()
      $('#wizard-finished').remove()
    else
      @_nextStep()

    wizard.find('.loading').fadeOut ()->
      wizard.find('ol.steps').fadeIn()

  _html: ()->
    @_current.html

  _node: ()->
    @_current.node
    
  _options: ()->
    @_current.options

  _addResource: (resource, step = @_current)->
    step.resources[resource.id] = { id: resource.id, name: @_getName(resource) }
    
  _createButton: (name, cssClass = null)->
    button = $("<button class='btn btn-sm blue #{cssClass}'>#{name}</button>")
    @_html().buttons.append(button)
    button

  _completeStep: ()->
    return unless @_current?
    @_renderResources()
    @_node().addClass('completed')
    @_node().find('.hide-on-complete').hide()

    if @_options().reEdit && @_html().buttons.find('.edit-again').length == 0
      reEditButton = @_createButton('Edit again', 'edit-again')
      reEditButton.on 'click', (event)=>
        @_current = @_findStepById($(event.currentTarget).closest('.step').prop('id'))
        @_renderStep()
        @_html().resources.hide()
        @_node().removeClass('completed')
        @_node().find('~ li.step').hide()

  _findStepById: (id)->
    (step for step in @_steps when step.id is id)[0]

  _getName: (resource)->
    resource.name || resource.title || resource.description

  _handleNextButton: ()->
    next = @_html().buttons.find('.next-step')
    if next.length > 0
      next.removeClass('disabled').tooltip('destroy')
    else
      @_nextStep()
      
  _nextStep: ()->
    if @_current
      @_completeStep()
      while @_current? && @_node().hasClass('completed')
        @_node().show()
        @_current = @_steps[@_steps.indexOf(@_current)+1]
    else
      @_current = @_steps[0]

    if @_current
      @_renderStep()
    else
      $('#wizard-finished').show()

  _prepareReferences: (step = @_current)->
    return {} unless step.options.references?
    references = {}
    for reference in step.options.references
      refStep = @_findStepById("#{reference}-step")
      ids = Object.keys(refStep.resources)
      if ids.length > 0
        if refStep.options.multiple
          references["#{reference}_ids"] = ids
        else
          references["#{reference}_id"] = ids[0]
    references

  _renderResources: (step = @_current)->
    container = step.html.resources
    container.empty()
    for id, resource of step.resources
      item = ((resource)=>
        item = $('<li>')
        item.append("<a href='#{step.options.url}/#{resource.id}' target='_blank'>#{@_getName(resource)} (ID: #{resource.id})</a>")
        button = @_createButton('Edit', 'btn-sm').attr('href', "#{step.options.url}/#{resource.id}/edit.js")
        button.on 'click', ( ()=> @_setupModal(button) )
        item.append(button)
      )(resource)
      container.append(item)
    container.show()

  _renderStep: ()->
    # reset settings to avoid conflicts
    $('#resource-modal').off('hide.bs.modal')
    window.resource = null

    # if step was already rendered then just show it
    if @_node().hasClass('rendered')
      @_node().show()
      return

    # select existing resource, only for require steps
    if @_options().select
      selectButton = @_createButton('Select existing', 'select-existing')
      selectButton.on 'click', ()=>
        inline = @_html().inline
        $.ajax
          url: '/admin/games/wizard.json'
          data: exact: false, filter: @_prepareReferences(), resource: @_current.id.replace('-step', '')
          method: 'GET'
          success: (response)=>
            if response.length > 0
              resources = ({ id: item.id, name: @_getName(item).trim() } for item in response).sort (a,b)->
                aName = a.name.toLowerCase()
                bName = b.name.toLowerCase()
                if aName > bName then 1 else if aName < bName then -1 else 0

              select = $("<select class='bs-select input-large'><option value=''></option></select>")
              for resource in resources
                select.append("<option value='#{resource.id}'>#{@_getName(resource)}</option>")
              select.on 'change', ()=>
                @_addResource({ id: select.val(), name: select.find('option:selected').text() })
                @_renderResources()
                @_handleNextButton()

              inline.append(select)
              Metronic.ComponentsBootstrapSelect.init()
            else
              inline.append($("<div class='form-text text-muted important'>There are no records, please create new one.</div>"))
          error: ()=>
            inline.append($("<div class='form-text text-muted important'>At the moment it wasn't possible to obtain list of existing resources.</div>"))
          complete: ()=>
            selectButton.hide()

    # create new resource
    if @_options().inline
      container = $("##{@_current.id}-inline")
      inline = @_html().inline
      url = container.data('url')

      if url?
        for key, value of @_prepareReferences()
          url = url.replace("$#{key}", value[0])
        inline.load "#{url}.js", ()=>
          window[@_options().function]?()
      else
        inline.html(container.html())
        window[@_options().function]?()
    else
      createButton = @_createButton('Create new', 'create-new').attr('href', "#{@_options().url}/new.js")
      createButton.on 'click', ( ()=> @_setupModal(createButton) )

    # go to next step, only for optional step or those which supports options.multiple resources
    if !@_options().required || @_options().multiple
      nextButton = @_createButton('Next', 'next-step')
      nextButton.on 'click', ( ()=> @_nextStep() unless nextButton.hasClass('disabled') )
      if @_options().required
        nextButton.addClass('disabled').tooltip(title: 'You must either select existing record or create new one before go to next step.', placement: 'right')

    if @_options().autoselect
      $.ajax
        url: '/admin/games/wizard.json'
        data: exact: true, filter: @_prepareReferences(), resource: @_current.id.replace('-step', '')
        method: 'GET'
        success: (response)=>
          for resource in response
            @_addResource(resource)
          @_renderResources()
          @_handleNextButton()

    @_node().addClass('rendered')
    @_node().show()
    
  _setupModal: (caller)->
    step = @_findStepById(caller.parents('li.step').prop('id'))
    modal = $('#resource-modal')
    modal.off 'hide.bs.modal'
    modal.on 'hide.bs.modal', ()=>
      if window.resource?
        @_addResource(window.resource, step)
        window.resource = null
        @_renderResources(step)
        @_handleNextButton() if step == @_current

    if modal.is(':visible')
      modal.modal('hide')
    else
      modal.modal('show', caller)