Metronic.ComponentsColorPickers = function() {
    var handleColorPicker = function () {
        if (!jQuery().colorpicker) {
            return;
        }
        $('.colorpicker-default').colorpicker({
            format: 'hex'
        });
        $('.colorpicker-rgba').colorpicker();
    };

    var handleMiniColors = function() {
        $('.minicolors-input').each(function() {
            //
            // Dear reader, it's actually very easy to initialize MiniColors. For example:
            //
            //  $(selector).minicolors();
            //
            // The way I've done it below is just for the demo, so don't get confused
            // by it. Also, data- attributes aren't supported at this time...they're
            // only used for this demo.
            //
            $(this).minicolors({
                control: $(this).attr('data-control') || 'hue',
                defaultValue: $(this).attr('data-defaultValue') || '',
                inline: $(this).attr('data-inline') === 'true',
                letterCase: $(this).attr('data-letterCase') || 'lowercase',
                opacity: $(this).attr('data-opacity'),
                position: $(this).attr('data-position') || 'bottom left',
                theme: 'bootstrap',
                keywords: 'transparent,initial,inherit'
            });

        });
    };

    return {
        //main function to initiate the module
        init: function() {
            handleMiniColors();
            handleColorPicker();
        }
    };

}();

jQuery(document).ready(function() {    
   Metronic.ComponentsColorPickers.init();
});