Manager.controller 'admin/homepage/texts',
  index: ()->
    form = $('#homepage-texts-form')
    submit = form.find('[type=submit]')

    form.find('textarea').on 'change', ()->
      submit.prop('disabled', true).tooltip(placement: 'bottom', title: submit.data('tooltip'))

    form.find('.preview').on 'click', ()->
      submit.prop('disabled', false).tooltip('dispose')
      window.open("/?#{form.serialize()}", '_blank')