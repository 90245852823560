require('javascripts/classes/wizard')

Manager.controller 'admin/games',
  wizard: ()->
    window.wizard = new USeek.Wizard() unless window.wizard?

    window.wizardHotspotsStep = ()->
      wizardSetupHotspots()

      hStep = window.wizard._findStepById('hotspot-step')
      vStep = window.wizard._findStepById('video-step')

      if Object.keys(vStep.resources).length > 1
        navigation = $('<ul class="nav nav-pills hide-on-complete"></ul>')
        active = true
        for id, video of vStep.resources
          navigation.append(((video)->
            item = $("<li><a>#{video.name} (ID: #{video.id})</a></li>")
            item.addClass('active') if active
            active = false
            url = "/admin/videos/#{video.id}/hotspots.js"
            item.on 'click', (event)->
              event.preventDefault()
              navigation.children().removeClass('active')
              item.addClass('active')
              hStep.html.inline.load url, ()->
                wizardSetupHotspots()
          )(video))
        hStep.html.inline.before(navigation)

    window.wizardSetupHotspots = ()->
      handleDatatables()
      handleModals()
      Manager.controller('admin/videos').trigger('hotspots')

      step = window.wizard._findStepById('hotspot-step')
      table = window.datatables['hotspots-table']
      table.on 'draw', ()->
        if table.data().length > 0
          step.resources = {}
          for resource in table.data()
            window.wizard._addResource(resource, step)
          window.wizard._handleNextButton()