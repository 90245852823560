window.USeek ||= {}

class USeek.Polygony
  dot_color:     '#0000FF'
  line_color:    '#FF0000'
  outline_color: '#999999'
  fill_color:    '#DDDDDD' 

  constructor: (@element_id, @point_filter) ->
    @canvas = document.getElementById(@element_id)
    @poly = []
    @allow_draw = true
    @context = @canvas.getContext('2d')
    @context.lineWidth = 1

    $(@canvas).on 'mousedown', (event)=>
      pos = @getCursorPosition(event)
      #console.log "Canvas position: #{pos}"
      @mousedown(pos)

  clear: ()->
    @context.clearRect 0, 0, @canvas.width, @canvas.height

  reset: ()=>
    @clear()
    @poly = []
    @allow_draw = true

  draw_complete: ()->
    @allow_draw = false
    @clear()
    @paint()
    $(this).trigger 'complete'

  paint: (options = {})->
    @context.globalAlpha = options.alpha ? 0.3
    @context.strokeStyle = options.outline_color ? @outline_color
    @context.fillStyle = options.fill_color ? @fill_color
    @context.lineWidth = 1
    @context.beginPath()
    poly = options.poly ? @poly

    for point in poly
      @context.lineTo point[0], point[1]
    
    @context.closePath()  
    @context.stroke()
    @context.fill()
    @context.globalAlpha = 1

  distance: (point1, point2)->
    xs = point2[0] - point1[0]
    ys = point2[1] - point1[1]
    Math.sqrt xs*xs + ys*ys

  getCursorPosition: (event)->
    USeek.getClass('Util').clickPosition event, $(@canvas)

  mousedown: (point)->
    return false unless @allow_draw
    point = @point_filter(point) if @point_filter
    return false unless @point_filter

    if @poly.length > 2 and @distance(@poly[0], point) < 10
      point = @poly[0] 
      @draw_complete()
    else
      @poly.push(point)
      len = @poly.length
      @line(@poly[len - 2], point, @line_color) if len > 1
      @dot(@poly[len - 2], @dot_color) if len > 1
      @dot(point, @dot_color)

  line: (start, end, color) ->
    @context.beginPath()
    @context.moveTo start[0], start[1]
    @context.lineTo end[0], end[1]
    @context.closePath()
    @context.strokeStyle = color
    @context.stroke()

  dot: (point, color) ->
    @context.beginPath()
    @context.arc(point[0], point[1], 3, 0, Math.PI*2, true)
    @context.closePath()
    @context.fillStyle = color
    @context.fill()

  capture: ()->
    opacity = 1
    coordinates = @poly
    @clear()
    
    fadeOut = (opacity)=>
      if opacity > 0
        @clear()
        @paint
          alpha: opacity
          outline_color: '#11EE11'
          fill_color: '#11EE11'

        setTimeout ()->
          fadeOut(opacity - 0.05)
        , 25
      else
        @reset()

    fadeOut(0.8)
    coordinates