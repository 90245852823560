Manager.controller 'admin/games',
  preview: ()->
    $('#preview_width').on 'change', ()->
      width = $(this).val()
      $('#useek-player-container').css('max-width', "#{width}px")
      $('#preview_js').val($('#preview_js').val().replace(/width: (.*?)px/, "width: #{width}px"))
      $('#preview_iframe').val($('#preview_iframe').val().replace(/max-width: (.*?)px/, "max-width: #{width}px"))

    handleClipboard($('#js-clipboard'))
    handleClipboard($('#iframe-clipboard'))

    iframe = $('#useek-player')[0]
    iframeInterval = setInterval ()->
      window = iframe.contentWindow
      if window? && window.USeek? && window.USeek.$?
        gui = window.USeek.$('#useek-player-gui')
        gui.removeClass('responsive') unless gui.hasClass('vertical-video')
        clearInterval(iframeInterval)