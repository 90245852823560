Manager.controller 'admin/games',
  prizes: ()->
    deletePrize = (event)->
      prizeRow = $(event.target).parents('.prize-row')
      prizeRow.fadeOut ()->
        $('.add-prize-grand').show() if prizeRow.hasClass('grand')
        prizeRow.remove()
        renderPrizeSelect()

    renderPrizeSelect = ()->
      activePrizes = ($(row).data('prize-id') for row in $('.prize-row'))
      available = (prize for prize in window.prizes when !(prize.id in activePrizes))
      options = ("<option value=#{prize.id}>#{prize.description}</option>" for prize in available)
      $('.add-prize select')
        .html("<option value=''>--- Select Prize ---</option>#{options.join('')}")
        .selectpicker('refresh')

    setupPrizeSettings = (element)->
      Manager.controller('admin/prizes').trigger('setup', element)

    if($('.prize-row.grand').length > 0)
      $('.add-prize-grand').hide()

    $('.prize-type-section').hide()
    for settings in $('.prize-settings')
      setupPrizeSettings($(settings))

    $('.add-prize-button').on 'click', (event)->
      select = $(event.target).parent().find('select')
      return unless select.val().length > 0

      type = select.prop('id').replace('add_prize_', '')
      $.ajax
        url: "/admin/games/#{$('#game_id').val()}/prize_row.js"
        method: 'POST'
        data: { prize_id: select.val(), prize_type: type }
        success: (response)->
          rowHtml = $(response).find('.prize-row')
          rowHtml.find("input[type=radio][value=#{type}]").prop('checked', true)
          rowHtml.find('.prize-type-section').hide()
          $(".prizes-list.#{type}").append(rowHtml)
          $('.add-prize-grand').hide() if type == 'grand'
          setupPrizeSettings(rowHtml.find('.prize-settings'))
          rowHtml.find('.delete-prize-button').on('click', deletePrize)
          renderPrizeSelect()

    $('.delete-prize-button').on('click', deletePrize)

    renderPrizeSelect()