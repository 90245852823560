Metronic.ComponentsLightbox = function () {
    var handleLightbox = function() {
        if($().ekkoLightbox) {
            var onShowModal = function() {
                var zIndex = 10040 + (10 * $('.modal:visible').length);
                $(this).css('z-index', zIndex);
                setTimeout(function() {
                    $('.modal-backdrop').not('.modal-stack').css('z-index', zIndex - 1).addClass('modal-stack');
                }, 0);
            };

            var onHiddenModal = function() {
                if ($('.modal:visible').length > 0) {
                    setTimeout(function() {
                        $(document.body).addClass('modal-open');
                    }, 0);
                }
            };

            $('[data-toggle="lightbox"]:not(.lightbox-ready)').addClass('lightbox-ready').on('click', function(event) {
                event.preventDefault();
                $(this).ekkoLightbox({
                    onShow: function() { onShowModal.bind($(this)[0]._$modal)() },
                    onHidden: function() { onHiddenModal.bind($(this)[0]._$modal)() }
                });
            });
            $('.modal:not(.support-many)').addClass('support-many').on({ 'show.bs.modal': onShowModal, 'hidden.bs.modal': onHiddenModal });
        }
    };

    return {
        //main function to initiate the module
        init: function () {
            handleLightbox();
        }
    };

}();

if (Metronic.App.isAngularJsApp() === false) {
    jQuery(document).ready(function() {
        Metronic.ComponentsLightbox.init();
    });
}