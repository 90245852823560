import { ExportToCsv } from 'export-to-csv';
require('javascripts/classes/game_stats')

Manager.controller 'admin/games',
  live_stats: ()->
    @trigger('stats')

    $('#disqualify-modal').on 'show.bs.modal', (event)->
      element = $(event.relatedTarget)
      if element?
        $(this).find('#user_id').val(element.data('user-id'))
        $(this).find('.user-email').html(element.data('user-email'))

  precise_stats: ()->
    @trigger('export')

    handlePieCharts()
    new GameStats($('#game-stats').data('source'), true)

  export: ()->
    $("#stats-export-button").on 'click', ()->
      data = []
      for name in $('.export-input:checked').map( ()-> $(this).val() ).get()
        data.push(name: name, value: $("#stats-content [data-export-as='#{name}']").text().trim())

      new ExportToCsv(filename: 'game-stats').generateCsv(data)

    $ ()->
      exportData = $('#stats-content [data-name]').map( ()-> $(this).data() ).get()
      if exportData.any() && $('#stats-export').length > 0
        $.post $('#stats-export').data('url'), { stats: exportData }, (response)=>
          $('#stats-export-list').html(response)
          $('#stats-export-list').find('input[data-switch]').on 'click', ()->
            $("##{$(this).data('switch')} input[type=checkbox]").prop('checked', $(this).prop('checked'))
          window.triggerAfterAjax()

  stats: ()->
    @trigger('export')

    for chart in $('div[data-chart]')
      ((chart)=>
        chart.find('.chart-btn').on 'click', ()->
          updateChart(chart.data('chart'), $(this).html(), $(this).data())
      )($(chart))

    $('#players-location-other-country').on 'changed.bs.select', ()->
      updateChart('players-location-chart', $(this).find('option:selected').text(), { region: $(this).val(), resolution: 'provinces' })

    handlePieCharts()
    new GameStats($('#game-stats').data('source'))