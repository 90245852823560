Metronic.ComponentsCkeditor = function () {
    var handleCkeditor = function() {
        if($().ckeditor) {
            $('.ckeditor').ckeditor();
        }
    };

    return {
        //main function to initiate the module
        init: function () {
            handleCkeditor();
        }
    };

}();

if (Metronic.App.isAngularJsApp() === false) {
    jQuery(document).ready(function() {
        Metronic.ComponentsCkeditor.init();
    });
}