Manager.controller 'admin/games',
  delete: ()->
    return unless $('#delete-game-form').length > 0

    $('[data-toggle="tooltip"]').tooltip()

    $('[data-hotspot]').bind 'change', () ->
      id = $(this).data('hotspot')
      checked = $(this).is(':checked')
      $("[data-hotspot='#{id}']").prop('checked', checked)
      $("[data-parent='hotspot-#{id}'").prop('checked', checked).change()

    $('[data-offer]').bind 'change', () ->
      id = $(this).data('offer')
      checked = $(this).is(':checked')
      $("[data-offer='#{id}']").prop('checked', checked)

    $('[data-prize]').bind 'change', () ->
      id = $(this).data('prize')
      checked = $(this).is(':checked')
      $("[data-prize='#{id}']").prop('checked', checked)

    $('[data-video]').bind 'change', () ->
      id = $(this).data('video')
      checked = $(this).is(':checked')
      $("[data-video='#{id}']").prop('checked', checked)
      $("[data-parent='video-#{id}'").prop('checked', checked).prop('disabled', checked).change()

    $('#delete-game-form').bind 'submit', (event) ->
      event.preventDefault()
      event.stopPropagation()
      confirm = $('#confirm-delete')

      if confirm.val() == 'YES'
        disabled = $(this).find('input:disabled')
        disabled.prop('disabled', false)
        if $(this).data('remote')?
          Rails.handleRemote.call(this, event)
        else
          this.trigger('submit')
        disabled.prop('disabled', true)
      else
        confirm.css('border', '1px solid #FF0000').focus()
        confirm.parent().css('color', '#FF0000')