String.prototype.camelize = ()->
  this.replace /^([A-Z])|[\s-_]+(\w)/g, (match, p1, p2, offset)->
    if (p2) then p2.toUpperCase() else p1.toLowerCase()

String.prototype.capitalize = ()->
  this.charAt(0).toUpperCase() + this.slice(1)

String.prototype.parameterize = ()->
  this.toLowerCase().replace(/[^a-z0-9_]+/g,'-').replace(/(^-|-$)/g,'')

String.prototype.underscore = ()->
  this.replace /[A-Z]/g, (match, offset)->
    "#{if offset > 0 then '_' else ''}#{match.toLowerCase()}"

String.prototype.toBoolean = ()->
  /^(true|t|yes|y|1)$/i.test(this)

String.prototype.toRGBA = (opacity = 1)->
  hex = this.replace('#','');
  r = parseInt(hex.substring(0,2), 16);
  g = parseInt(hex.substring(2,4), 16);
  b = parseInt(hex.substring(4,6), 16);
  "rgba(#{r},#{g},#{b},#{opacity})"