require('javascripts/utils/number')

window.directUpload = (uploadSuccess = null)->
  $('.direct-upload').each ()->
    form = $(this)
    input = form.find('input[type=file]')
    progressBar = $("<div class='progress-bar progress-bar-striped bg-success'></div>")
    barContainer = $("<div class='progress'></div>").append(progressBar)
    input.after(barContainer)

    input.on 'change', ()->
      $.ajax
        url: form.prop('action'),
        type: 'POST',
        dataType: 'xml',
        data: new FormData(form[0]),
        contentType: false,
        processData: false,

        xhr: ()->
          xhr = $.ajaxSettings.xhr()
          if xhr.upload
            xhr.upload.addEventListener 'progress', (event)->
              percent = 0;
              position = event.loaded || event.position;
              total = event.total;
              if (event.lengthComputable)
                percent = Math.ceil(position / total * 100)
                progressBar.css('width', percent + '%')
          xhr

        beforeSend: ()->
          progressBar.css('width', '0%').text("Uploading...")

        success: (response)->
          data = $(response)
          files = $('#uploaded-videos-list')
          preview = $('#uploaded-videos-preview')

          if files.length > 0
            node = $("<li><a>#{data.find('Key').text().split('/')[1]}</a></li>")
            node.on 'click', (event)->
              event.preventDefault()
              preview.prop('src', data.find('Location').text()).show()
              preview[0].play()
            files.append(node)

          progressBar.text("File uploaded")
          uploadSuccess?(data)

        error: ()->
          progressBar.removeClass('bg-success').addClass('bg-danger').text("Failed")