Metronic.ComponentsDateTimePickers = function () {
    var handleDatePickers = function () {
        if (jQuery().datetimepicker) {
            $('.date-picker').datetimepicker({
                format: "MM/DD/YYYY",
                buttons: { showToday: true },
                icons: { today: 'far fa-calendar-check' },
                widgetPositioning: { horizontal: 'left', vertical: 'bottom' }
            });
        }
    };

    var handleTimePickers = function () {
        if (jQuery().datetimepicker) {
            $('.time-picker').datetimepicker({
                format: "hh:mm A",
                widgetPositioning: { horizontal: 'left', vertical: 'bottom' }
            });
        }
    };

    var handleDatetimePicker = function () {
        if (jQuery().datetimepicker) {
            $(".datetime-picker").datetimepicker({
                format: "MM/DD/YYYY hh:mm A",
                icons: { time: 'far fa-clock' },
                useCurrent: 'day',
                widgetPositioning: { horizontal: 'left', vertical: 'bottom' }
            });
        }
    };

    return {
        //main function to initiate the module
        init: function () {
            handleDatePickers();
            handleTimePickers();
            handleDatetimePicker();
        }
    };

}();

if (Metronic.App.isAngularJsApp() === false) {
    jQuery(document).ready(function() {    
        Metronic.ComponentsDateTimePickers.init();
    });
}