Manager.controller 'admin/hotspots',
  form: ()->
    container = $('.hotspot_image_setup')
    checkboxes = container.find('input[type=checkbox]:visible')
    preview = container.find('.image-preview')

    checkboxes.on 'change', ()->
      status = $(this).prop('checked')
      checkboxes.prop('checked', false)
      $(this).prop('checked', status)
      preview.toggleClass('hidden', status)

    $('#hotspot_image').on 'change', ()->
      checkboxes.prop('checked', false)