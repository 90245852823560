Manager.controller 'admin/games',
  edit_html: ()->
    window.deleteCustomButton = ()->
      buttonRow = $(this).parents('.registered-button')
      if buttonRow.hasClass('new-record')
        buttonRow.fadeOut ( ()-> buttonRow.remove() )
      else
        buttonRow.find('input[type=checkbox]').prop('checked', true)
        buttonRow.hide().addClass('deleted')

    $('#add-custom-registered-button').on 'click', ()->
      index = $('.registered-button').length
      html = $('.registered-button:first').clone()
      html.html(html.html().replace(/\[0\]/g, "[#{index}]"))
      html.html(html.html().replace(/_0_/g, "_#{index}_"))
      html.find('.button-header').html('Custom button settings')
      html.find('.button-type').val("custom_#{index}")
      html.find('.image-preview').remove()
      html.find('input.string').val('')
      html.addClass('custom-button new-record')

      $('#custom-registered-buttons').append(html)
      Metronic.App.initBootstrapConfirmation()

    editForm = $('#game-html-form')
    keepModal = editForm.find('input[name=keep_modal]')
    playerIframe = $('#useek-player')
    previewButton = $('.preview-button .dropdown-toggle')

    $('input[type=submit]').on 'click', ()->
      keepModal.val(false)

    editForm.on 'ajax:beforeSend', () ->
        Rails.disableElement(previewButton[0]) if $(this).data('preview')

    editForm.on 'ajax:success', ()->
      preview = $(this).data('preview')
      if preview
        playerIframe.prop('src', playerIframe.data('src')+'&preview_section='+preview)
        $('#useek-player-container').show().parents('.portlet-body').scrollTop(0)

    playerIframe.on 'load', ()->
      USeek = playerIframe[0].contentWindow.USeek
      if USeek?
        player = USeek.player()
        player.gui().removeClass('responsive') unless player.gui().hasClass('component-vertical')
        editForm.data(preview: null)
        Rails.enableElement(previewButton[0])

    showPreview = (screen)->
      keepModal.val(true)
      editForm.data(preview: screen)

    $('.preview-button a[data-preview]').on 'click', ()->
      showPreview($(this).data('preview'))
      previewButton.dropdown('hide')
      Rails.fire(editForm[0], 'submit')
    $('.save-button').on 'click', ()->
      keepModal.val(false)