Manager.datatable 'user-events-table',
  load: ()->
    $('tr.details-event').hide()
    $('a.expand-button').on 'click', (event)->
      event.preventDefault()
      button = $(event.target)
      row = button.parents('tr')
      details = $("tr.details-event[play_id=#{row.attr('play_id')}]")
      if row.hasClass('expanded')
        details.removeClass('success').hide()
        button.text('Expand')
        row.removeClass('expanded')
      else
        details.addClass('success').show()
        button.text('Collapse')
        row.addClass('expanded')