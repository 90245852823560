require('javascripts/utils/array')

class window.GameStats
  constructor: (source, useCache = false)->
    @_cache = {}
    @_observers = {}
    @_requests = { current: 0, max: 2 }
    @_sections = []
    @_source = source
    @_stats = []

    $('*[data-stats], *[data-stats-chart]').each ( (index, element)=> @configure($(element)) )
    @_sections = (section for section in @_sections.compact() when (section.charts.any() || section.stats.any()))

    if useCache
      @fetchStats(@_stats)
      @fetchCharts(section.charts) for section in @_sections when section.charts.any()
    else
      @fetch()

  configure: (node)->
    config = node.data()
    section = (@_sections[config.sectionId-1] ||= { charts: [], stats: [] })

    if config.stats?
      stats = config.stats
      stats = [stats] unless Array.isArray(stats)
      for stat in stats
        @subscribe(stat, node)
        unless @_stats.indexOf(stat) >= 0
          section.stats.push(stat) unless section.stats.indexOf(stat) >= 0
          @_stats.push(stat)

    if config.statsChart?
      section.charts.push(id: config.statsChart, url: config.url)

  fetchCharts: (charts)->
    return unless charts.any()
    for chart in charts
      Chartkick.charts[chart.id].updateData(chart.url)

  fetchStats: (stats)->
    return unless stats.any()

    @_requests.current++
    $.post @_source, { stats: stats }, (response)=>
      @_requests.current--
      for name, value of response
        @_cache[name] = value
        @notify(name)

  fetch: ()->
    if @_requests.current < @_requests.max
      section = @_sections.shift()
      if section?
        @fetchStats(section.stats)
        @fetchCharts(section.charts)

    setTimeout(@fetch.bind(@), 200) if @_sections.any()

  get: (name, item = null)->
    value = @_cache[name]
    return unless value?
    if item? && value[item]? then value[item] else value

  notify: (name)->
    for node in @_observers[name]
      @update(node)

  subscribe: (name, observer)->
    @_observers[name] ||= []
    @_observers[name].push(observer) unless @_observers[name].indexOf(observer) >= 0

  update: (node)->
    statFn = node.data('function')
    value = if statFn? then @[statFn]?(node) else @get(node.data('stats'), node.data('item'))
    node.html(value.toLocaleString('en-US')) if value?

  # stats methods

  completionRate: (node)->
    value = @percent(@division(node, 10))
    return unless value?

    node.parents('.progress-info').find('.progress-bar').css(width: "#{value.toFixed(1)}%")
    "#{value.toFixed(1)}%"

  division: (node, precision)->
    precision ||= node.data('precision') || 2
    stats = node.data('stats')
    value = @get(stats.first())
    total = @get(stats.last())
    return unless value? && total?
    return 0 if total == 0
    (value/total).toFixed(precision)

  percent: (value)->
    if value? then value*100 else null

  pieChart: (node)->
    value = @percent(@division(node, 10))
    return unless value?

    node.parent().data('easyPieChart').update(value);
    "#{value.toFixed(1)}%"

  topX: (node)->
    node.parent().find('.widget-filter select:not(.ready)').addClass('ready').on 'change', (event)=>
      node.data('item', $(event.target).val())
      @update(node)

    data = {}
    index = 0
    max = node.data('elements')
    other = 0
    for name, value of @get(node.data('stats'), node.data('item') || 'total')
      index += 1
      if index < max
        data[name] = value
      else
        other += value
    if other > 0
      data['Other'] = other

    content = ''
    for name, value of data
      content += "
        <div class='widget-thumb-wrap'>
          <div class='widget-thumb-body float-right'>
            <div class='widget-thumb-subtitle'>#{name}</div>
            <div class='widget-thumb-body-stat float-right'>#{value.toLocaleString('en-US')}</div>
          </div>
        </div>"

    if content.length == 0
      content = "<br />No data"

    content

  total: (node, operator = +1)->
    stats = node.data('stats')
    values = (@get(stat) for stat in stats)
    return if values.indexOf(null) >= 0
    values.shift() + (value*operator for value in values).sum()