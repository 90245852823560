require('javascripts/classes/polygony')

Manager.controller 'admin/videos',
  hotspots: ()->
    pointFilter = (point)->
      util = USeek.getClass('Util')
      snapped = util.normalizedPosition(point, $('#video-element'), util.aspectRatio(), true)
      util.denormalizedPosition(snapped, $('#video-element'))

    window.canvas = new USeek.Polygony('polygon', pointFilter)
    window.hotspotCanvas = new USeek.Polygony('hotspot-display')

    $('#clear-hotspot').on 'click', (event)->
      event.preventDefault()
      window.canvas.reset()

Manager.datatable 'hotspots-table',
  load: ()->
    captureModals()

Manager.player 'admin/videos',
  hotspots: (player)->
    player.bind 'update-timer', (data)->
      window.hotspotCanvas.clear()
      for hotspot in player.getVideo().hotspots
        showMe = data.position >= hotspot.startTime && data.position <= hotspot.endTime
        if showMe
          points = player.util().denormalizedPositions(hotspot.coordinates, $('#video-element'))
          window.hotspotCanvas.paint( poly: points, online_color: '#3f7cd4', fill_color: '#3f7cd4' )
        $("tr#hotspot_#{hotspot.id}").toggleClass 'success', showMe