class window.Manager
  @_callbacks = {}
  @_scopes = ['controller', 'datatable', 'player']

  for scope in @_scopes
    ((scope)=>
      @_callbacks[scope] = {}
      this[scope] = ( (name, action, callback)=> @setup(scope, name, action, callback) )
    )(scope)

  @error: (message)->
    throw "USeek Manager: #{message}"

  @setup: (scope, name, actions = {})->
    @error("Unknown scope. Available scopes: #{@_scopes.join(', ')}.") unless @_scopes.indexOf(scope) >= 0
    @error("Missing controller name") unless name?

    controller = @_callbacks[scope][name] ||= new Controller()
    for action, callback of actions
      controller.bind(action, callback)

    controller

class window.Controller
  constructor: ()->
    @_actions = {}

  bind: (name, callback)->
    @_actions[name] = callback

  trigger: (name, attributes...)->
    @_actions[name].bind(this)(attributes...) if @_actions[name]?
    @_actions.form.bind(this)(attributes...) if name in ['new', 'create', 'edit', 'update'] && @_actions.form?